<template>
	<div style="width: 100%;overflow-y: hidden;" :style="{ height: `${viewportHeight}px` }"> 
		<iframe :src="iframeUrl" width="100%" height="100%" frameborder="0" allowfullscreen></iframe>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				http: 'https://baidu.com?urlLink=https://www.taobao.com/',
				iframeUrl: '',
				viewportHeight: 0
			};
		},
		mounted() {
			// 获取 http 中的 urlLink 参数值
			this.iframeUrl = this.getUrlParamValue(this.http, 'urlLink');
			// console.log(this.urlLinkValue);


			// 获取当前窗口的可视化高度
			this.viewportHeight = window.innerHeight;
			// 监听窗口大小变化，更新可视化高度
			window.addEventListener('resize', this.updateViewportHeight);
		},
		beforeUnmount() {
			// 在组件销毁前移除 resize 事件监听器，防止内存泄漏
			window.removeEventListener('resize', this.updateViewportHeight);
		},
		methods: {
			updateViewportHeight() {
				// 更新可视化高度
				this.viewportHeight = window.innerHeight;
			},
			getUrlParamValue(url, paramName) {
				// 使用正则表达式获取参数值
				let regex = new RegExp('[?&]' + paramName + '(=([^&#]*)|&|#|$)');
				let results = regex.exec(url);
				if (!results) return null;
				if (!results[2]) return '';
				return decodeURIComponent(results[2].replace(/\+/g, ' '));
			}
		}

	};
</script>
<style>
	
</style>
